import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import ValueCreateModal from './ValueCreateModal';
import { MDBCard, MDBCardBody, MDBTable, MDBCardHeader, MDBIcon, MDBBtn } from 'mdbreact';

class List extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = { valueModalOpen: false };
  }

  valueModalToggle = () => {
    this.setState({ valueModalOpen: ! this.state.valueModalOpen });
  }

  valueCreate = params => {
    this.props.valueCreate(this.props.match.params.id, params)
      .then(() => this.valueModalToggle());
  }

  valueRemove = id => () => {
    this.props.confirmationToggle(() => this.props.valueRemove(id));
  }

  render() {
    return (
      <Fragment>
        <MDBCard narrow>
          <MDBCardHeader className="na-card-header">
            <h2>Filters</h2>
            <MDBBtn outline rounded size="md" color="primary" onClick={this.valueModalToggle}><MDBIcon icon="plus"/>Create</MDBBtn>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBTable responsive hover small striped className="table--sortable table--narrow">
              <thead>
                <tr>
                  <th className="th">ID</th>
                  <th className="th">Title</th>
                  <th className="th">#</th>
                </tr>
              </thead>
              <tbody>
                {this.props.list.map(el => (
                  <tr className="pointer" key={el.id} onClick={() => {}}>
                    <td>{el.id}</td>
                    <td>{el.title}</td>
                    <td><MDBIcon icon="trash" className="pointer" onClick={this.valueRemove(el.id)} /></td>
                  </tr>
                ))}
              </tbody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
        <ValueCreateModal open={this.state.valueModalOpen} toggle={this.valueModalToggle} submit={this.valueCreate} />
      </Fragment>
    );
  }
}

export default withRouter(List);
