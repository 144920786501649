import { connect } from 'react-redux';
import { filtersSet } from './../../actions/Posts';
import Table from './../../components/Posts/Table';

const mapState = state => ({
  orderBy: state.posts.filters.orderBy,
  loading: state.posts.loading,
  list: state.posts.list
});

const mergeProps = (stateProps, dispatchProps) => {
  const { orderBy, loading, list } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    list,
    loading,
    orderHandler: key => dispatch(filtersSet({
      orderBy: orderBy !== key ? key : '-'+ key,
      page: 1
    }))
  };
}

export default connect(mapState, null, mergeProps)(Table);
