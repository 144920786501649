import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol, MDBSelect, MDBInput, MDBIcon, MDBFileInput, MDBChipsInput } from 'mdbreact';
import ImagesLightbox from './../ImagesLightbox';
import CharacteristicColors from './CharacteristicColors';
import Card from './../Atoms/Card';
import { notification } from './../../actions/App';

class Edit extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      title: '',
      priceShop: '',
      priceDownload: '',
      link: '',
      youtube: '',
      instagram: '',
      arModels: [],
      filters: {},
      images: [],
      files: [],
      colors: [],
      shopId: null,
      hashtags: [],
      characteristics: [
        { key: 'Ширина', value: '', colors: [] },
        { key: 'Высота', value: '', colors: [] },
        { key: 'Глубина', value: '', colors: [] },
        { key: '', value: '', colors: [] }
      ],
      characteristicsColors: {}
    };

    this.hashtags = React.createRef();
  }

  componentDidMount() {
    this.props.getPost(this.props.match.params.id)
      .then(data => {
        data.characteristics.push({  key: '', value: '', colors: [] });
        data.files = data.file !== null ? [ data.file ] : [];
        this.setState(data);
        this.hashtags.current.setState({ chipsList: this.props.chipsList });
      });
  }

  shopHandle = value => {
    this.setState({ shopId: value[0] });
  }

  colorsHandle = val => {
    this.setState({ colors: val });
  }

  selectHandle = key => values => {
    this.setState(state => ({ filters: { ...state.filters, [key]: values } }));
  }

  arModelHandler = fileList => {
    Object.keys(fileList).map(key => {
      let reader = new FileReader();

      reader.onloadend = () => {
        const allow = [ 'usdz', 'glb' ];
        const extention = fileList[key].name.split('.').pop();

        if ( !allow.includes(extention) ) {
          notification('error', 'Неверный формат файла.');
          return false;
        }

        this.setState({ arModels: [ ...this.state.arModels, { name: fileList[key].name } ] });
        this.props.arModelUpload({
          file: reader.result,
          name: fileList[key].name,
          postId: this.state.id,
          extention: extention,
        });
      };

      reader.readAsDataURL(fileList[key]);
    });
  }

  arModelRemove = id => () => {
    this.props.confirmationToggle(() => {;
      const arModels = this.state.arModels;

      this.setState({ arModels: arModels.filter((el) => el.id !== id) });
      this.props.arModelRemove(id);
    });
  }

  filesHandler = fileList => {
    Object.keys(fileList).map(key => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ files: [ ...this.state.files, { src: reader.result, name: fileList[key].name } ] });
      }

      reader.readAsDataURL(fileList[key])
    });
  }

  fileRemove = index => () => {
    this.props.confirmationToggle(() => {;
      const files = this.state.files;

      this.setState({ files: files.filter((el, i) => i !== index) });

      if ( files[index].id !== undefined ) this.props.fileRemove(files[index].id);
    });
  }

  imagesHandler = fileList => {
    Object.keys(fileList).map(key => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ images: [ ...this.state.images, { src: reader.result, avatar: false } ] });
      }

      reader.readAsDataURL(fileList[key])
    });
  }

  setAvatar = index => {
    const images = [ ...this.state.images ];

    if ( ! images[index].avatar ) {

      images.map((el, i) => el.avatar = i === index)
      this.setState({ images: images });

      if ( images[index].id !== undefined ) this.props.imageSetAvatar(images[index].id);
    }
  }

  imageRemove = index => {
    const images = this.state.images;

    this.setState({ images: images.filter((el, i) => i !== index) });

    if ( images[index].id !== undefined ) this.props.imageRemove(images[index].id);
  }

  inputHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  characteristicAdd = () => {
    this.setState({ characteristics: [ ...this.state.characteristics, {  key: '', value: '', colors: [] } ] });
  }

  characteristicHandler = index => event => {
    let characteristics = [ ...this.state.characteristics ];

    characteristics[index][event.target.name] = event.target.value;
    this.setState({ characteristics });

    if ( characteristics.length === ( index + 1 ) ) this.characteristicAdd();
  }

  characteristicColorsHandler = index => colors => {
    this.state.characteristicsColors[index] = colors;
  }

  getData = () => {
    const characteristicsColors = this.state.characteristicsColors;
    let characteristics = [ ...this.state.characteristics ];

    Object.keys(characteristicsColors).map(index =>
      ( characteristics[index] !== undefined ) ? characteristics[index].colors = characteristicsColors[index] : null
    );

    return {
      id: this.state.id,
      shopId: this.state.shopId,
      title: this.state.title,
      priceShop: this.state.priceShop,
      priceDownload: this.state.priceDownload,
      link: this.state.link,
      youtube: this.state.youtube,
      instagram: this.state.instagram,
      files: this.state.files,
      images: this.state.images,
      colors: this.state.colors,
      filters: this.state.filters,
      characteristics: characteristics,
      hashtags: this.hashtags.current.state.chipsList
    }
  }

  draftHandler = () => {
    const data = this.getData();

    data.state = 'draft';
    this.props.update(data);
    this.props.history.push('/posts');
  }

  publishHandler = () => {
    const data = this.getData();

    data.state = 'published';
    this.props.update(data);
    this.props.history.push('/posts');
  }

  viewHandle = () => {
    window.open(process.env.REACT_APP_CLIENT_URL +'/posts/'+ this.state.id, '_blanc').focus();
  }

  removeHandler = () => {
    this.props.confirmationToggle(() => this.props.remove(this.props.match.params.id).then(() => this.props.history.push('/posts')));
  }

  render() {
    const { filtersOptions, shopsOptions, colorsOptions, chipsList } = this.props;
    const { arModels, title, priceShop, priceDownload, link, youtube, instagram, images, files, characteristics } = this.state;

    const cardActions = [
      {
        title: 'Remove',
        color: 'secondary',
        onClick: this.removeHandler
      }, {
        title: 'Save Draft',
        onClick: this.draftHandler
      }, {
        title: 'Publish',
        onClick: this.publishHandler
      }, {
        title: 'View',
        onClick: this.viewHandle
      }
    ];

    return (
      <Card
        title="Post Edit"
        actions={cardActions}
        body={(
          <Fragment>
            <div className="table__filters">
              <MDBRow>
                {filtersOptions.map(el => (
                  <MDBCol xs="12" sm="6" md="3" key={el.id}>
                    <MDBSelect ref={el=>this.temS} multiple options={el.options} label={el.label} getValue={this.selectHandle(el.id)} />
                  </MDBCol>
                ))}
              </MDBRow>
            </div>
            <MDBSelect search options={shopsOptions} label="Select Shop" getValue={this.shopHandle} />
            <MDBInput label="Title" name="title" value={title || ''} onChange={this.inputHandler} />
            <MDBInput label="Price Download" name="priceDownload" value={priceDownload} onChange={this.inputHandler} />
            <MDBInput label="Price Shop" name="priceShop" value={priceShop || ''} onChange={this.inputHandler} />
            <MDBInput label="Link to Shop" name="link" value={link || ''} onChange={this.inputHandler} />
            <MDBInput label="Link to YouTube" name="youtube" value={youtube || ''} onChange={this.inputHandler} />
            <MDBInput label="Link to Instagram" name="instagram" value={instagram || ''} onChange={this.inputHandler} />
            <MDBSelect search multiple options={colorsOptions} label="Select Colors" getValue={this.colorsHandle} />
            <MDBChipsInput placeholder='+Tag' secondaryPlaceholder='Enter a tag' ref={this.hashtags} chips={chipsList} />
            <h3>Characteristics</h3>
            {characteristics.map((el, i) => (
              <MDBRow key={i}>
                <MDBCol>
                  <MDBInput label="Key" name="key" value={el.key} onChange={this.characteristicHandler(i)} />
                </MDBCol>
                <MDBCol>
                  <MDBInput label="Value" name="value" value={el.value} onChange={this.characteristicHandler(i)} />
                </MDBCol>
                <MDBCol>
                  <CharacteristicColors colors={el.colors} handle={this.characteristicColorsHandler(i)} />
                </MDBCol>
              </MDBRow>
            ))}
            <h3>Images</h3>
            <MDBFileInput multiple getValue={this.imagesHandler} />
            <ImagesLightbox images={images} onAvatar={this.setAvatar} onRemove={this.imageRemove} />
            <hr />
            <h3>Files</h3>
            <MDBFileInput multiple getValue={this.filesHandler} />
            {files.map((el, i) => (
              <MDBRow key={i}>
                <MDBCol size="1">{i + 1}</MDBCol>
                <MDBCol size="10" className="text-left">
                  {el.id !== undefined ? (<a className="pointer" href={el.src} target="_blanc">{el.name}</a>) : el.name}
                </MDBCol>
                <MDBCol size="1"><MDBIcon icon="trash" className="pointer" onClick={this.fileRemove(i)} /></MDBCol>
              </MDBRow>
            ))}
            <hr />
            <h3>AR Models</h3>
            <MDBFileInput getValue={this.arModelHandler} />
            {arModels.map((el, i) => (
              <MDBRow key={i}>
                <MDBCol size="1">{i + 1}</MDBCol>
                <MDBCol size="10" className="text-left">
                  {el.id !== undefined ? (<a className="pointer" href={el.source} target="_blanc">{el.name}</a>) : el.name}
                </MDBCol>
                <MDBCol size="1">{el.id && (<MDBIcon icon="trash" className="pointer" onClick={this.arModelRemove(el.id)} />)}</MDBCol>
              </MDBRow>
            ))}
          </Fragment>
        )}
      />
    );
  }
}

export default withRouter(Edit);
