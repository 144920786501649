import React, { PureComponent } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class Modal extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = { id: null, title: '', description: null, state: null };
  }

  componentDidUpdate(prevProps) {
    if ( this.props.data !== undefined && this.props.data.id !== this.state.id ) {
      this.setState({ ...this.props.data });
    }
  }

  render() {
    const { open, toggle } = this.props;
    const { title, description } = this.state;

    return (
      <MDBModal isOpen={open} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Question</MDBModalHeader>
        <MDBModalBody>
          <h5>{title}</h5>
          <p>{description}</p>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

export default Modal;
