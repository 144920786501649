import React, { PureComponent, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol, MDBSelect, MDBInput, MDBFileInput, MDBChipsInput, MDBIcon } from 'mdbreact';
import ImagesLightbox from './../ImagesLightbox';
import CharacteristicColorsNew from './CharacteristicColorsNew';
import Card from './../Atoms/Card';

class Create extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      priceShop: '₴',
      priceDownload: '',
      link: '',
      youtube: '',
      instagram: '',
      filters: {},
      images: [],
      files: [],
      colors: [],
      shopId: null,
      characteristics: [
        { key: 'Ш/В/Г', value: '//', colors: [] },
        { key: 'Матеріал', value: '', colors: [] },
        { key: 'Виробник', value: 'Україна', colors: [] },
        { key: '', value: '', colors: [] }
      ]
    };
    this.hashtags = React.createRef();
  }

  filterHandle = key => val => {
    let filters = { ...this.state.filters };

    filters[key] = val;
    this.setState({ filters });
  }

  shopHandle = value => {
    this.setState({ shopId: value[0] });
  }

  colorsHandle = val => {
    this.setState({ colors: val });
  }

  filesHandler = fileList => {
    Object.keys(fileList).map(key => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ files: [ ...this.state.files, { src: reader.result, name: fileList[key].name } ] });
      }

      reader.readAsDataURL(fileList[key])
    });
  }

  fileRemove = index => () => {
    this.setState({ files: this.state.files.filter((el, i) => i !== index) });
  }

  imagesHandler = fileList => {
    Object.keys(fileList).map(key => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ images: [ ...this.state.images, { src: reader.result, avatar: false } ] });
      }

      reader.readAsDataURL(fileList[key])
    });
  }

  setAvatar = index => {
    const images = [ ...this.state.images ];

    if ( ! images[index].avatar ) {

      images.map((el, i) => el.avatar = i === index)
      this.setState({ images: images });
    }
  }

  imageRemove = index => {
    this.setState({ images: this.state.images.filter((el, i) => i !== index) });
  }

  inputHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  characteristicAdd = () => {
    this.setState({ characteristics: [ ...this.state.characteristics, {  key: '', value: '', colors: [] } ] });
  }

  characteristicHandler = index => event => {
    let characteristics = [ ...this.state.characteristics ];

    characteristics[index][event.target.name] = event.target.value;
    this.setState({ characteristics });

    if ( characteristics.length === ( index + 1 ) ) this.characteristicAdd();
  }

  characteristicColorsHandler = index => colors => {
    let characteristics = [ ...this.state.characteristics ];

    characteristics[index].colors = colors;
    this.setState({ characteristics });
  }

  getData = () => {
    return {
      shopId: this.state.shopId,
      title: this.state.title,
      priceShop: this.state.priceShop,
      priceDownload: this.state.priceDownload,
      link: this.state.link,
      youtube: this.state.youtube,
      instagram: this.state.instagram,
      filters: this.state.filters,
      images: this.state.images,
      files: this.state.files,
      colors: this.state.colors,
      characteristics: this.state.characteristics,
      hashtags: this.hashtags.current.state.chipsList
    };
  }

  draftHandler = () => {
    const data = this.getData();

    data.state = 'draft';
    this.create(data);
  }

  publishHandler = () => {
    const data = this.getData();

    data.state = 'published';
    this.create(data);
  }

  create = data => {
    this.props.create(data).then(id => this.props.history.push('/posts/'));
  }

  render() {
    const { filtersOptions, colorsOptions, shopsOptions } = this.props;
    const { title, priceShop, priceDownload, link, youtube, instagram, images, files, characteristics } = this.state;

    const cardActions = [
      {
        title: 'Save Draft',
        onClick: this.draftHandler
      }, {
        title: 'Publish',
        onClick: this.publishHandler
      }
    ];

    return (
      <Card
        title="Post Create"
        actions={cardActions}
        body={(
          <Fragment>
            <div className="table__filters">
              <MDBRow>
                {filtersOptions.map(el => (
                  <MDBCol xs="12" sm="6" md="3" key={el.id}>
                    <MDBSelect multiple options={el.options} label={el.label} getValue={this.filterHandle(el.id)} />
                  </MDBCol>
                ))}
              </MDBRow>
            </div>
            <MDBSelect search options={shopsOptions} label="Select Shop" getValue={this.shopHandle} />
            <MDBInput label="Title" name="title" value={title} onChange={this.inputHandler} />
            <MDBInput label="Price Download" name="priceDownload" value={priceDownload} onChange={this.inputHandler} />
            <MDBInput label="Price Shop" name="priceShop" value={priceShop} onChange={this.inputHandler} />
            <MDBInput label="Link to Shop" name="link" value={link} onChange={this.inputHandler} />
            <MDBInput label="Link to YouTube" name="youtube" value={youtube} onChange={this.inputHandler} />
            <MDBInput label="Link to Instagram" name="instagram" value={instagram} onChange={this.inputHandler} />
            <MDBSelect search multiple options={colorsOptions} label="Select Colors" getValue={this.colorsHandle} />
            <MDBChipsInput placeholder='+Tag' secondaryPlaceholder='Enter a tag' ref={this.hashtags} />
            <h3>Characteristics</h3>
            {characteristics.map((el, i) => (
              <MDBRow className="characteristic-row" key={i}>
                <MDBCol>
                  <MDBInput label="Key" name="key" value={el.key} onChange={this.characteristicHandler(i)} />
                </MDBCol>
                <MDBCol>
                  <MDBInput label="Value" name="value" value={el.value} onChange={this.characteristicHandler(i)} />
                </MDBCol>
                <MDBCol>
                  <CharacteristicColorsNew handle={this.characteristicColorsHandler(i)} />
                </MDBCol>
              </MDBRow>
            ))}
            <h3 className="mt-20">Images</h3>
            <MDBFileInput multiple getValue={this.imagesHandler} />
            <ImagesLightbox images={images} onAvatar={this.setAvatar} onRemove={this.imageRemove} />
            <hr />
            <h3>Files</h3>
            <MDBFileInput getValue={this.filesHandler} />
            {files.map((el, i) => (
              <MDBRow key={i}>
                <MDBCol size="1">{i + 1}</MDBCol>
                <MDBCol size="10" className="text-left">
                  {el.id !== undefined ? (<a className="pointer" href={el.src} target="_blanc">{el.name}</a>) : el.name}
                </MDBCol>
                <MDBCol size="1"><MDBIcon icon="trash" className="pointer" onClick={this.fileRemove(i)} /></MDBCol>
              </MDBRow>
            ))}
          </Fragment>
        )}
      />
    );
  }
}

export default withRouter(Create);
