import * as types from './../constants/ActionTypesPosts';

const initialState = {
  filters: {
    shopId: null,
    page: 1,
    limit: 50,
    orderBy: '-id'
  },
  loading: true,
  pagesCount: 0,
  list: []
};

export default (state = initialState, action) => {
  switch ( action.type ) {

    case types.POSTS_FILTERS_SET:
      return { list: [], pagesCount: 0, loading: true, filters: action.payload };

    case types.POSTS_FETCHED:
      return { ...state, ...action.payload, loading: false };

    default:
      return state;
  }
};
