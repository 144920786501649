import * as types from './../constants/ActionTypesPosts';
import axiosDispatch from './../axios/dispatch';
import { getList as getHashtags } from './Hashtags';
import { notification } from './App';

export function arModelUpload(params) {
  return dispatch => {
    axiosDispatch(dispatch, '/files/uploadArModel', params)
      .catch(error => notification('error', error));
  }
}

export function arModelRemove(id) {
  return dispatch => {
    axiosDispatch(dispatch, '/files/removeArModel', { id })
      .catch(error => notification('error', error));
  }
}

export function getList(params) {
  return (dispatch, getState) => {
    const filters = { ...getState().posts.filters, ...params };

    axiosDispatch(dispatch, '/posts/list', filters)
      .then(data => dispatch(fetchList(data)));
  }
}

export function getPost(id) {
  return dispatch => {
    return new Promise(resolve => {
      axiosDispatch(dispatch, '/posts/get', { id })
        .then(data => {
          resolve(data);
          dispatch(fetchItem(data));
        });
    })
  }
}

export function fetchList(data) {
  return {
    type: types.POSTS_FETCHED,
    payload: data
  };
}

export function fetchItem(data) {
  return {
    type: types.POST_FETCH,
    payload: data
  };
}

export function create(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      axiosDispatch(dispatch, '/posts/create', params)
        .then(data => {
          dispatch(getList());
          dispatch(getHashtags());
          notification('success', 'Post Created');
          resolve(data.id);
        })
        .catch(error => notification('error', error));
    });
  }
}

export function update(params) {
  return dispatch => {
    axiosDispatch(dispatch, '/posts/update', params)
      .then(data => {
        dispatch(getList());
        dispatch(getHashtags());
        dispatch(fetchItem(data));
        notification('success', 'Post Updated');
      })
      .catch(error => notification('error', error));
  }
}

export function remove(id) {
  return dispatch => {
    return new Promise(resolve => {
      axiosDispatch(dispatch, '/posts/remove', { id })
        .then(data => {
          dispatch(getList());
          notification('success', 'Post Removed');
          resolve();
        })
        .catch(error => notification('error', error));
    });
  }
}

export function fileRemove(id) {
  return dispatch => {
    axiosDispatch(dispatch, '/files/delete', { id })
      .catch(error => notification('error', error));
  }
}

export function imageRemove(id) {
  return dispatch => {
    axiosDispatch(dispatch, '/images/delete', { id })
      .catch(error => notification('error', error));
  }
}

export function imageSetAvatar(id) {
  return dispatch => {
    axiosDispatch(dispatch, '/images/setAvatar', { id })
      .catch(error => notification('error', error));
  }
}

export function filtersSet(params) {
  return (dispatch, getState) => {
    const filters = { ...getState().posts.filters, ...params };

    dispatch({
      type: types.POSTS_FILTERS_SET,
      payload: filters
    });
    dispatch(getList(filters));
  }
}
