import * as types from './../constants/ActionTypesPosts';

const initialState = {
  id: null,
  shopId: null,
  title: '',
  link: '',
  youtube: '',
  images: [],
  colors: [],
  hashtags: [],
  filtersValues: [],
  characteristics: [],
  filters: {}
};

export default (state = initialState, action) => {
  switch ( action.type ) {

    case types.POST_FETCH:
      return action.payload;

    default:
      return state;
  }
};
