import React, { PureComponent, Fragment } from 'react';
import EditModal from './EditModal';
import { MDBCard, MDBCardBody, MDBTable, MDBCardHeader, MDBIcon } from 'mdbreact';

class List extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = {
      editModalOpen: false,
      id: null
    };
    props.getList();
  }

  editModalToggle = id => {
    this.setState({ editModalOpen: ! this.state.editModalOpen, id: id });
  }

  update = params => {
    this.props.update(params)
      .then(() => this.setState({ editModalOpen: false, id: null }));
  }

  render() {
    const list = this.props.list;
    const id = this.state.id;

    return (
      <Fragment>
        <MDBCard narrow>
          <MDBCardHeader className="na-card-header">
            <h2>Contacts</h2>
          </MDBCardHeader>
          <MDBCardBody>
            <MDBTable responsive hover small striped className="table--sortable table--narrow">
              <thead>
                <tr>
                  <th className="th">ID</th>
                  <th className="th">User</th>
                  <th className="th">Title</th>
                  <th className="th">#</th>
                </tr>
              </thead>
              <tbody>
                {list.map(el => (
                  <tr className="pointer" key={el.id} onClick={() => {}}>
                    <td>{el.id}</td>
                    <td>{el.email}</td>
                    <td>{el.title}</td>
                    <td><MDBIcon icon="pencil-alt" className="pointer" onClick={() => this.editModalToggle(el.id)} /></td>
                  </tr>
                ))}
              </tbody>
            </MDBTable>
          </MDBCardBody>
        </MDBCard>
        <EditModal open={this.state.editModalOpen} toggle={this.editModalToggle} submit={this.update} data={list.find(el => el.id === id)} />
      </Fragment>
    );
  }
}

export default List;
