import { connect } from 'react-redux';
import { update, remove, getPost, imageSetAvatar, imageRemove, fileRemove, arModelUpload, arModelRemove } from './../../actions/Posts';
import { confirmationToggle } from './../../actions/App';
import PostEdit from './../../components/Posts/Edit';

const filtersOptions = (filters, values) => {
  let rv = [];

  filters.map(el => {
    let filter = { id: el.id, label: el.title, options: [] };

    el.values.map(el => filter.options.push({ text: el.title, value: el.id, checked: values.includes(el.id) }));

    rv.push(filter);
  });

  return rv;
}

const shopsOptions = (shops, value) => {
  let rv = [];

  shops.map(el => rv.push({ text: el.title, value: el.id, icon: el.src, checked: el.id === value }));

  return rv;
}

const colorsOptions = (colors, values) => {
  let rv = [];

  colors.map(el => rv.push({ text: el.title, value: el.id, icon: el.src, checked: values.includes(el.id) }));

  return rv;
}

const chipsList = (hashtags, values) => {
  let rv = [];

  hashtags.map(el => values.includes(el.id) ? rv.push(el.tag) : null);

  return rv;
}

const mapStateToProps = state => {
  return {
    filtersOptions: filtersOptions(state.filters, state.post.filtersValues),
    colorsOptions: colorsOptions(state.colors, state.post.colors),
    shopsOptions: shopsOptions(state.shops, state.post.shopId),
    chipsList: chipsList(state.hashtags, state.post.hashtags),
    post: state.post
  }
};

export default connect(mapStateToProps, { update, remove, getPost, imageSetAvatar, imageRemove, fileRemove, confirmationToggle, arModelUpload, arModelRemove })(PostEdit);
