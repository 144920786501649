import React, { PureComponent } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
import Lightbox from 'react-image-lightbox';
import './index.css';

class LightboxPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { photoIndex: 0, isOpen: false };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const { images, onRemove, onAvatar = null } = this.props;

    return (
      <MDBContainer>
        <div className="mdb-lightbox no-margin">
          <MDBRow>
            {images.map((el, key) => (
              <MDBCol md="4" key={key}>
                <figure className="p-relative">
                  <MDBBtn className="img-btn-avatar close" size="sm" color="danger" floating rounded onClick={() => onAvatar(key)}>
                    <MDBIcon far={!el.avatar} icon="star" />
                  </MDBBtn>
                  {( onRemove !== null ) ? (
                    <MDBBtn className="img-btn-remove close" size="sm" color="danger" floating rounded onClick={() => onRemove(key)}>
                      <MDBIcon icon="trash" />
                    </MDBBtn>
                  ) : null}
                  <img
                    src={el.src} alt="Gallery" className="img-fluid"
                    onClick={() => this.setState({ photoIndex: key, isOpen: true })}
                  />
                </figure>
              </MDBCol>
            ))}
          </MDBRow>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
            imageTitle={photoIndex + 1 + '/' + images.length}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length })
            }
            onMoveNextRequest={() =>
              this.setState({ photoIndex: (photoIndex + 1) % images.length })
            }
          />
        )}
      </MDBContainer>
    );
  }
}

export default LightboxPage;
