import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { MDBSideNavNav, MDBSideNav } from 'mdbreact';
import './index.css';

class NavbarLeft extends PureComponent
{
  render() {
    return (
      <MDBSideNav
        logo="/img/logo.png"
        triggerOpening={this.props.navbarLeftOpen}
        breakWidth={this.props.breakWidth}
        bg="https://mdbootstrap.com/img/Photos/Others/sidenav4.jpg"
        mask="strong"
        fixed
      >
        <MDBSideNavNav>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/posts">Posts</Link>
          </li>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>
            <Link to="/filters">Filters</Link>
          </li>
          <li>
            <Link to="/shops">Shops</Link>
          </li>
          <li>
            <Link to="/colors">Colors</Link>
          </li>
        </MDBSideNavNav>
      </MDBSideNav>
    )
  }
}

export default NavbarLeft;
