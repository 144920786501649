import { connect } from 'react-redux';
import { getData as userGet } from './../actions/User';
import { getList as postsGetList } from './../actions/Posts';
import { getList as filtersGetList } from './../actions/Filters';
import { getList as hashtagsGetList } from './../actions/Hashtags';
import { getList as colorsGetList } from './../actions/Colors';
import { getList as shopsGetList } from './../actions/Shops';
import Content from './../components/Content';

export default connect(null, { userGet, postsGetList, filtersGetList, hashtagsGetList, colorsGetList, shopsGetList })(Content);
