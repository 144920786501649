import * as types from './../constants/ActionTypesUser';
import axiosDispatch from './../axios/dispatch';
import { notification } from './App';

export function getData() {
  return dispatch => axiosDispatch(dispatch, '/users/me')
    .then(data => dispatch({
      type: types.USER_FETCH,
      payload: data
    }))
    .catch(error => notification('error', error));
}

export function update(params) {
  return dispatch => axiosDispatch(dispatch, '/users/update', params)
    .then(data => dispatch({
      type: types.USER_FETCH,
      payload: data
    }))
    .catch(error => notification('error', error));
}
