import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Pagination from './../../containers/Posts/Pagination';
import Table from './../../containers/Posts/Table';
import Filters from './../../containers/Posts/Filters';
import Card from './../Atoms/Card';

const List = ({ history }) => {
  const cardActions = [{
    icon: 'plus',
    title: 'Create',
    onClick: () => history.push('/posts/create')
  }];

  return (
    <Card
      title="Posts"
      actions={cardActions}
      body={(
        <Fragment>
          <Filters />
          <Table />
          <Pagination />
        </Fragment>
      )}
    />
  );
}

export default withRouter(List);
