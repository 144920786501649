import React from 'react';
import { MDBRow, MDBCol } from 'mdbreact';
import ShopsSelect from './../../containers/Shops/Select';
import PostsSelectStatus from './SelectStatus';
import SelectLimit from './../Atoms/Select/Limit';

const Filters = ({ shopId, status, limit, filtersSet }) => {
  const onSelect = key => value => filtersSet({ [key]: value, page: 1 });

  return (
    <form className="box-shadow-wrapper" autoComplete="off">
      <MDBRow>
        <MDBCol xs="12" sm="6" md="3">
          <ShopsSelect search label="Shop" value={shopId} onSelect={onSelect('shopId')} />
        </MDBCol>
        <MDBCol xs="12" sm="6" md="3">
          <PostsSelectStatus value={status} onSelect={onSelect('status')} />
        </MDBCol>
        <MDBCol xs="12" sm="6" md="3">
        </MDBCol>
        <MDBCol xs="12" sm="6" md="3">
          <SelectLimit value={limit} onSelect={onSelect('limit')} />
        </MDBCol>
      </MDBRow>
    </form>
  );
}

Filters.propTypes = {};

export default Filters;
