import * as types from './../constants/ActionTypesApp';

const initialState = {
  confirmation: {
    callback: () => {},
    open: false
  }
};

export default (state = initialState, action) => {

  switch ( action.type ) {

    case types.APP_CONFIRMATION_TOGGLE:
      return { ...state, confirmation: { callback: action.payload.callback, open: ! state.confirmation.open } };

    case types.APP_CONFIRMATION_CONFIRM:
      return { ...state, confirmation: { callback: () => {}, open: false } };

    default:
      return state;
  }
};
