// Just because NDBootstrap select is very funny we need one more component
import React from 'react';
import { connect } from 'react-redux';
import { MDBSelect } from 'mdbreact';

const colorsOptions = (colors) => {
  let rv = [];

  colors.map(el => rv.push({ text: el.title, value: el.id, icon: el.src }));

  return rv;
}

const mapStateToProps = state => ({ colorsOptions: colorsOptions(state.colors) });

export default connect(mapStateToProps)(props => (
  <MDBSelect search multiple options={props.colorsOptions} label="Colors" getValue={props.handle} />
));
