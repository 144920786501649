import React from 'react';
import PropTypes from 'prop-types';
import Select from './../Atoms/Select/index';

const options = value => ([
  { text: 'All', value: null, checked: !value },
  { text: 'Published', value: 'published', checked: value === 'published' },
  { text: 'Moderation', value: 'moderation', checked: value === 'moderation' },
  { text: 'Draft', value: 'draft', checked: value === 'draft' }
]);

const SelectStatus = ({ value, onSelect }) => {
  const selectHandler = val => {
    if ( val !== value ) onSelect(val);
  }

  return (
    <Select label="Status" options={options(value)} onSelect={selectHandler} />
  );
};

SelectStatus.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};

export default SelectStatus;
