import React, { PureComponent } from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBInput } from 'mdbreact';

class Modal extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = { title: '' };
  }

  inputHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  submitHandler = () => {
    this.props.submit({ title: this.state.title });
  }

  render() {
    const { open, toggle } = this.props;

    return (
      <MDBModal isOpen={open} toggle={toggle}>
        <MDBModalHeader toggle={toggle}>Filter Value Create</MDBModalHeader>
        <MDBModalBody>
          <MDBInput label="Title" name="title" value={this.state.title} onChange={this.inputHandler} />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={toggle}>Close</MDBBtn>
          <MDBBtn color="primary" onClick={this.submitHandler}>Save</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

export default Modal;
