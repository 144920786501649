import React, { PureComponent } from 'react';
import Loader from './../Atoms/Loader';
import Card from './../Atoms/Card';

class Dashboard extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      data: {}
    }

    props.getData()
      .then(data => this.setState({ data, loading: false }))
      .catch(error => this.setState({ loading: false, error }));
  }

  render() {
    const { loading, data } = this.state;
    const { usersCount } = data;

    return (
      <Card
        title="Dashboard"
        body={loading
          ? (<Loader />)
          : (<p>Users count: {usersCount}</p>)
        }
      />
    );
  }
}

export default Dashboard;
