import React from 'react';
import PropTypes from 'prop-types';
import Select from './index';

const options = value => ([
  { text: '20', value: '20', checked: value === 20 },
  { text: '50', value: '50', checked: value === 50 },
  { text: '100', value: '100', checked: value === 100 },
  { text: '200', value: '200', checked: value === 200 }
]);

const SelectLimit = ({ label, value, onSelect }) => {
  const selectHandler = val => {
    val = Number(val);

    if ( val !== value ) onSelect(val);
  }

  return (
    <Select label={label} options={options(value)} onSelect={selectHandler} />
  );
};

SelectLimit.defaultProps = {
  label: 'Display Per Page',
  value: 50
};

SelectLimit.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  onSelect: PropTypes.func.isRequired
};

export default SelectLimit;
