import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import NavbarLeft from './NavbarLeft';
import NavbarTop from './NavbarTop';

const DoubleNavigation = ({ signOut, children }) => {
  const [ navbarLeftOpen, setNavbarLeftOpen ] = useState(false);
  const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);

  const handleResize = () => setWindowWidth(window.innerWidth);

  const navbarLeftToggle = () => setNavbarLeftOpen(!navbarLeftOpen);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const breakWidth = 1300;
  const mainStyle = {
    margin: '0 6%',
    paddingTop: '5.5rem',
    paddingLeft: windowWidth > breakWidth ? '240px' : '0'
  };

  return (
    <BrowserRouter>
      <div className="fixed-sn white-skin">
        <NavbarLeft navbarLeftOpen={navbarLeftOpen} breakWidth={breakWidth} />
        <NavbarTop windowWidth={windowWidth} breakWidth={breakWidth} navbarLeftToggle={navbarLeftToggle} signOut={signOut} />
        <main style={mainStyle}>
          {children}
        </main>
      </div>
    </BrowserRouter>
  );
};

export default DoubleNavigation;
