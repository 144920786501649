import * as types from './../constants/ActionTypesContacts';
import axiosDispatch from './../axios/dispatch';

export function getList() {
  return dispatch => {
    axiosDispatch(dispatch, '/contacts')
      .then(data => dispatch(fetchList(data)));
  }
}

export function fetchList(data) {
  return {
    type: types.CONTACTS_FETCH,
    payload: data
  };
}

export function update(params) {
  return dispatch => {
    axiosDispatch(dispatch, '/contacts/update', params)
      .then(() => dispatch(getList()));
  }
}
