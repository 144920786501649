import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './../containers/Dashboard';
import Filters from './../containers/Filters';
import FiltersEdit from './../containers/Filters/Edit';
import Posts from './../components/Posts';
import Profile from './../containers/User/Profile';
import PostEdit from './../containers/Posts/Edit';
import PostCreate from './../containers/Posts/Create';
import Support from './../components/Support';
import Contacts from './../containers/Contacts';
import Users from './../components/Users';
import Colors from './../containers/Colors';
import Shops from './../containers/Shops';

export default () => (
  <Switch>
    <Route exact path="/" component={Dashboard} />
    <Route exact path="/filters" component={Filters} />
    <Route exact path="/filters/:id" component={FiltersEdit} />
    <Route exact path="/posts" component={Posts} />
    <Route exact path="/posts/edit/:id" component={PostEdit} />
    <Route exact path="/posts/create" component={PostCreate} />
    <Route exact path="/users" component={Users} />
    <Route exact path="/support" component={Support} />
    <Route exact path="/contacts" component={Contacts} />
    <Route exact path="/profile" component={Profile} />
    <Route exact path="/colors" component={Colors} />
    <Route exact path="/shops" component={Shops} />
  </Switch>
);
