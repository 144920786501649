import React, { Component } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBInput,
  MDBBtn,
  MDBFileInput
} from 'mdbreact';

class Profile extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      id: props.id || null,
      email: props.email || '',
      name: props.name || '',
      avatar: props.avatar || null,
      image: props.image || null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.setState({
        id: this.props.id,
        email: this.props.email,
        name: this.props.name,
        avatar: this.props.avatar
      });
    }
  }

  inputHandle = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  imageHandler = fileList => {
    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({ image: reader.result });
    }

    reader.readAsDataURL(fileList[0]);
  }

  saveHandle = () => {
    this.props.update({
      id: this.state.id,
      email: this.state.email,
      name: this.state.name,
      image: this.state.image
    });
  }

  render() {
    const { email, name, avatar, image } = this.state;
    const src = image !== null ? image : avatar;

    return (
      <MDBCard narrow>
        <MDBCardHeader className="na-card-header">
          <h2>User Edit</h2>
          <div>
            <MDBBtn outline rounded size="md" color="primary" onClick={this.saveHandle}>Update</MDBBtn>
          </div>
        </MDBCardHeader>
        <MDBCardBody>
          <MDBInput label="Email" name="email" value={email} onChange={this.inputHandle} />
          <MDBInput label="Name" name="name" value={name} onChange={this.inputHandle} />

          <h3>Avatar</h3>
          <MDBRow>
            <MDBCol col="8">
              <MDBFileInput getValue={this.imageHandler} />
            </MDBCol>
            <MDBCol col="4">
              {src !== null ? (<img className="mwh-100" src={src} alt="Avatar" />) : null}
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default Profile;
