import * as types from './../constants/ActionTypesApp';
import { toast } from 'mdbreact';

export function notification(type, message) {
  toast[type](message);
}

export function confirmationToggle(callback = () => {}) {
  return {
    type: types.APP_CONFIRMATION_TOGGLE,
    payload: { callback }
  }
}

export function confirmationConfirm() {
  return (dispatch, getState) => {
    getState().app.confirmation.callback();
    dispatch({ type: types.APP_CONFIRMATION_CONFIRM });
  }
}
