import { connect } from 'react-redux';
import { create } from './../../actions/Posts';
import PostCreate from './../../components/Posts/Create';

const filtersOptions = filters => {
  let rv = [];

  filters.map(el => {
    let filter = { id: el.id, label: el.title, options: [] };

    el.values.map(el => filter.options.push({ text: el.title, value: el.id }));

    rv.push(filter);
  });

  return rv;
}

const shopsOptions = shops => {
  let rv = [];

  shops.map(el => rv.push({ text: el.title, value: el.id, icon: el.src }));

  return rv;
}

const colorsOptions = colors => {
  let rv = [];

  colors.map(el => rv.push({ text: el.title, value: el.id, icon: el.src }));

  return rv;
}

const mapStateToProps = state => {
  return {
    filtersOptions: filtersOptions(state.filters),
    colorsOptions: colorsOptions(state.colors),
    shopsOptions: shopsOptions(state.shops)
  }
};

export default connect(mapStateToProps, { create })(PostCreate);
