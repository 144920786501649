import { connect } from 'react-redux';
import { filtersSet } from './../../actions/Posts';
import Pagination from './../../components/Atoms/Pagination';

const mapState = state => ({
  current: state.posts.filters.page,
  pagesCount: state.posts.pagesCount
});

const mapDispatch = dispatch => ({
  setPage: page => dispatch(filtersSet({ page }))
});

export default connect(mapState, mapDispatch)(Pagination);
