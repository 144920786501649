import React, { PureComponent, Fragment } from 'react';
import CreateModal from './CreateModal';
import EditModal from './EditModal';
import { MDBTable, MDBIcon } from 'mdbreact';
import Card from './../Atoms/Card';

class List extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = {
      createModalOpen: false,
      editModalOpen: false,
      id: null
    };
  }

  createModalToggle = () => {
    this.setState({ createModalOpen: ! this.state.createModalOpen });
  }

  editModalToggle = (id = null) => {
    this.setState({ editModalOpen: ! this.state.editModalOpen, id: id });
  }

  create = params => {
    this.props.create(params)
      .then(() => this.createModalToggle());
  }

  update = params => {
    this.props.update(params)
      .then(() => this.setState({ editModalOpen: false, id: null }));
  }

  remove = id => () => {
    this.props.confirmationToggle(() => this.props.remove(id));
  }

  render() {
    const list = this.props.list;
    const id = this.state.id;

    const cardActions = [{
      icon: 'plus',
      title: 'Create',
      onClick: this.createModalToggle
    }];

    return (
      <Fragment>
        <Card
          title="Shops"
          actions={cardActions}
          body={(
            <MDBTable responsive hover small striped className="table--sortable table--narrow">
              <thead>
                <tr>
                  <th className="th">ID</th>
                  <th className="th">Title</th>
                  <th className="th">Image</th>
                  <th className="th">#</th>
                  <th className="th">#</th>
                </tr>
              </thead>
              <tbody>
                {list.map(el => (
                  <tr className="pointer" key={el.id} onClick={() => {}}>
                    <td>{el.id}</td>
                    <td>{el.link !== null ? (<a href={el.link} target="_blank">{el.title}</a>) : el.title}</td>
                    <td><img className="h-20" src={el.src} /></td>
                    <td><MDBIcon icon="pencil-alt" className="pointer" onClick={() => this.editModalToggle(el.id)} /></td>
                    <td><MDBIcon icon="trash" className="pointer" onClick={this.remove(el.id)} /></td>
                  </tr>
                ))}
              </tbody>
            </MDBTable>
          )}
        />
        <CreateModal open={this.state.createModalOpen} toggle={this.createModalToggle} submit={this.create} />
        <EditModal open={this.state.editModalOpen} toggle={this.editModalToggle} submit={this.update} data={list.find(el => el.id === id)} />
      </Fragment>
    );
  }
}

export default List;
