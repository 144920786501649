import { connect } from 'react-redux';
import { valueCreate, valueRemove } from './../../actions/Filters';
import { confirmationToggle } from './../../actions/App';
import Values from './../../components/Filters/Edit';

const mapStateToProps = (state, props) => {
  const filterId = parseInt(props.match.params.id);

  return { list: state.filters.length !== 0 ? state.filters.find(el => el.id === filterId).values : [] };
}

export default connect(mapStateToProps, { valueCreate, valueRemove, confirmationToggle })(Values);
