import * as types from './../constants/ActionTypesFilter';
import axiosDispatch from './../axios/dispatch';

export function getList() {
  return dispatch => {
    axiosDispatch(dispatch, '/content/filters')
      .then(data => dispatch(fetchList(data)));
  }
}

export function fetchList(data) {
  return {
    type: types.FILTERS_FETCH,
    payload: data
  };
}

export function valueCreate(filterId, params) {
  return dispatch => {
    return new Promise(resolve => {
      axiosDispatch(dispatch, '/filters/valueCreate', { filterId, ...params })
        .then(() => {
          dispatch(getList());
          resolve();
        });
    });
  }
}

export function valueRemove(id) {
  return dispatch => {
    axiosDispatch(dispatch, '/filters/valueRemove', { id })
      .then(() => dispatch(getList()));
  }
}