import { connect } from 'react-redux';
import Select from './../../components/Atoms/Select';

const selectOptions = (shops, value) => {
  let rv = [ { text: 'All', value: null, checked: !value } ];

  shops.map(el => rv.push({ text: el.title, value: el.id, icon: el.src, checked: value === el.id }));

  return rv;
}

const mapState = (state, props) => ({
  options: selectOptions(state.shops, props.value),
  onSelect: shopId => {
    if ( shopId !== props.value ) props.onSelect(shopId);
  }
});

export default connect(mapState)(Select);
