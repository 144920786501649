import React from 'react';
import { withRouter } from 'react-router-dom';
import Loader from './../Atoms/Loader';
import Table from './../Atoms/Table';

export default withRouter(({ list, loading, history, orderHandler }) => (
  <Table
    head={(
      <tr>
        <th>#</th>
        <th onClick={() => orderHandler('id')} className="th">ID</th>
        <th onClick={() => orderHandler('title')} className="th">Title</th>
        <th onClick={() => orderHandler('author')} className="th">Shop</th>
        <th onClick={() => orderHandler('dateCreated')} className="th">Created</th>
      </tr>
    )}
    body={loading
      ? (<tr><td colSpan="5"><Loader /></td></tr>)
      : list.map(el => (
        <tr className="pointer" key={el.id} onClick={() => history.push('/posts/edit/'+ el.id)}>
          <td>{el.rowIndex}</td>
          <td>{el.id}</td>
          <td>{el.title}</td>
          <td>{el.shopTitle}</td>
          <td>{el.dateCreated}</td>
        </tr>
      ))
    }
  />
));
