import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBTable } from 'mdbreact';
import Card from './../Atoms/Card';

const List = ({ list, history }) => (
  <Card
    title="Filters"
    body={(
      <MDBTable responsive hover small striped className="table--sortable table--narrow">
        <thead>
          <tr>
            <th className="th">ID</th>
            <th className="th">Title</th>
            <th className="th text-center">Values</th>
          </tr>
        </thead>
        <tbody>
          {list.map(el => (
            <tr className="pointer" key={el.id} onClick={() => history.push('/filters/'+ el.id)}>
              <td>{el.id}</td>
              <td>{el.title}</td>
              <td className="text-center">{el.values.length}</td>
            </tr>
          ))}
        </tbody>
      </MDBTable>
    )}
  />
);

export default withRouter(List);
