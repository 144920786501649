import * as types from './../constants/ActionTypesHashtags';
import axiosDispatch from './../axios/dispatch';

export function getList() {
  return dispatch => {
    axiosDispatch(dispatch, '/hashtags')
      .then(data => dispatch(fetchList(data)));
  }
}

export function fetchList(data) {
  return {
    type: types.HASHTAGS_FETCH,
    payload: data
  };
}
