import { combineReducers } from 'redux';
import hashtags from './hashtags';
import filters from './filters';
import colors from './colors';
import shops from './shops';
import posts from './posts';
import post from './post';
import auth from './auth';
import user from './user';
import app from './app';
import contacts from './contacts';

export default combineReducers({
  hashtags,
  filters,
  colors,
  shops,
  posts,
  post,
  auth,
  user,
  app,
  contacts
});
