import * as types from './../constants/ActionTypesShops';
import axiosDispatch from './../axios/dispatch';

export function getList() {
  return dispatch => {
    axiosDispatch(dispatch, '/shops')
      .then(data => dispatch(fetchList(data)));
  }
}

export function fetchList(data) {
  return {
    type: types.SHOPS_FETCH,
    payload: data
  };
}

export function create(params) {
  return dispatch => {
    return new Promise(resolve => {
      axiosDispatch(dispatch, '/shops/create', params)
        .then(() => {
          dispatch(getList());
          resolve();
        });
    });
  }
}

export function update(params) {
  return dispatch => {
    return new Promise(resolve => {
      axiosDispatch(dispatch, '/shops/update', params)
        .then(() => {
          dispatch(getList());
          resolve();
        });
    });
  }
}

export function remove(id) {
  return dispatch => {
    axiosDispatch(dispatch, '/shops/remove', { id })
      .then(() => dispatch(getList()));
  }
}