// Just because NDBootstrap select is very funny
import React from 'react';
import { connect } from 'react-redux';
import { MDBSelect } from 'mdbreact';

const colorsOptions = (colors, selected) => {
  let rv = [];

  colors.map(el => rv.push({ text: el.title, value: el.id, icon: el.src, checked: selected.includes(el.id) }));

  return rv;
}

const mapStateToProps = (state, ownProps) => {
  let selectedColors = [];

  ownProps.colors.map(el => selectedColors.push(el.id));

  return { colorsOptions: colorsOptions(state.colors, selectedColors) };
}

export default connect(mapStateToProps)(props => (
  <MDBSelect search multiple options={props.colorsOptions} label="Colors" getValue={props.handle} />
));
