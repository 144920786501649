import React, { PureComponent } from 'react';
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class Confirmation extends PureComponent {
  render() {
    return (
      <MDBModal isOpen={this.props.open} toggle={this.props.confirmationToggle} side position="top-right">
        <MDBModalHeader toggle={this.props.confirmationToggle}>Are you sure?</MDBModalHeader>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={this.props.confirmationConfirm}>Yes</MDBBtn>
          <MDBBtn color="primary" onClick={this.props.confirmationToggle}>Close</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

export default Confirmation;
