import React, { PureComponent, Fragment } from 'react';
import CreateModal from './CreateModal';
import { MDBTable, MDBIcon } from 'mdbreact';
import Card from './../Atoms/Card';

class List extends PureComponent
{
  constructor(props) {
    super(props);
    this.state = { createModalOpen: false };
  }

  createModalToggle = () => {
    this.setState({ createModalOpen: ! this.state.createModalOpen });
  }

  create = params => {
    this.props.create(params)
      .then(() => this.createModalToggle());
  }

  remove = id => () => {
    this.props.confirmationToggle(() => this.props.remove(id));
  }

  render() {
    const cardActions = [{
      icon: 'plus',
      title: 'Create',
      onClick: this.createModalToggle
    }];

    return (
      <Fragment>
        <Card
          title="Colors"
          actions={cardActions}
          body={(
            <MDBTable responsive hover small striped className="table--sortable table--narrow">
              <thead>
                <tr>
                  <th className="th">ID</th>
                  <th className="th">Title</th>
                  <th className="th">Image</th>
                  <th className="th">#</th>
                </tr>
              </thead>
              <tbody>
                {this.props.list.map(el => (
                  <tr className="pointer" key={el.id} onClick={() => {}}>
                    <td>{el.id}</td>
                    <td>{el.title}</td>
                    <td><img className="h-20" src={el.src} /></td>
                    <td><MDBIcon icon="trash" className="pointer" onClick={this.remove(el.id)} /></td>
                  </tr>
                ))}
              </tbody>
            </MDBTable>
          )}
        />
        <CreateModal open={this.state.createModalOpen} toggle={this.createModalToggle} submit={this.create} />
      </Fragment>
    );
  }
}

export default List;
