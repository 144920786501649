import React, { Fragment } from 'react';
import Navigation from './../containers/Navigation';
import Confirmation from './../containers/App/Confirmation';
import { MDBContainer, ToastContainer } from 'mdbreact';
import Routes from './../routes';

export default props => {
  props.postsGetList();
  props.shopsGetList();
  props.colorsGetList();
  props.hashtagsGetList();
  props.filtersGetList();
  props.userGet();

  return (
    <Fragment>
      <Navigation>
        <MDBContainer fluid className="mt-3 pb-5 h-100">
          <Routes />
          <ToastContainer closeButton={false} />
          <Confirmation />
        </MDBContainer>
      </Navigation>
    </Fragment>
  );
}
